body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  cursor: crosshair;
}

#root {
  height: 100%;
}

.header {
  border-bottom: black 1px solid;
  width: 100%;
  padding-bottom: 3px;
  margin-top: -3px;
}

.intro {
  font-size: 32px;
  /* margin-bottom: 5px; */
  margin-left: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.subtext {
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-transform: uppercase;
}

.secondSubtext {
  margin-left: 30px;
}